import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Heading, Text, Img } from "../../components";
import { Link, Navigate, useParams } from "react-router-dom";
import Footer from "components/Footer";
import { getFilms, getSequencesHome2,getSequencesHome } from '../../services/filmsServices';
import AdminGestionPageDistributeurNavbar from "components/AdminGestionPageDistributeurNavbar";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import HomeColumntime from "components/HomeColumntime";

export default function CommunautePage() {
  const { producteur } = useParams();
  const [videos, setVideos] = useState([]);
  const [producteurName, setProducteurName] = useState(null);
  const [producteurColor, setProducteurColor] = useState("#000000");
  const [listeVideos, setListeVideos] = useState([]);
  const [films, setFilms] = useState([]);
  const [isBottom, setIsBottom] = useState(false);
  const [nbpage, setNbpage] = useState(1);
  const [dataLoading , setDataLoading] = useState(true);
  const [dataFilmsLoading , setDataFilmsLoading] = useState(true);
  const appUrl = process.env.REACT_APP_URL;

  const fetchVideos = async (page) => {
    try {
      setDataLoading(true);
      const videoData = await getSequencesHome2(page,producteur);
      setVideos(videoData.sequences);
      setListeVideos(prevListe => [...prevListe, videoData.sequences]);
      console.log(listeVideos);
      setDataLoading(false);
    } catch (error) {
      console.error("Error fetching videos:", error);
      setDataLoading(false);
    } finally {
      setDataLoading(false);
    }
  };

  const fetchFilms = async () => {
    setDataFilmsLoading(true);
    try {
      const filmsData = await getFilms();
      console.log(filmsData.films);
      const filtreFilmsData = filmsData.films.filter((film)=>film.producteur==producteur);
      setFilms(filtreFilmsData);
      setDataFilmsLoading(false);
    } catch (error) {
      console.error("Error fetching films:", error);
      setDataFilmsLoading(false);
    } finally {
      setDataFilmsLoading(false);
    }
  };

  const handleScroll = async () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight) {
      setIsBottom(true);
      const nextPage = nbpage + 1;
      setNbpage(nextPage);
      fetchVideos(nextPage);
    } else {
      setIsBottom(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    if(producteur == "pathe") {
        setProducteurName("Pathé");
        setProducteurColor("#fbba03")
    }else if(producteur == "recut"){
        setProducteurName("ReCut");
    }else if(producteur == "studiocanal"){
      setProducteurName("Studiocanal");
      setProducteurColor("#008DD0")
    }else if(producteur == "tandem"){
      setProducteurName("Tandem");
      setProducteurColor("#75978b")
    }else{
        setProducteurName("NO");
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [nbpage]);

  useEffect(() => {
    fetchFilms();
    fetchVideos(nbpage);
  }, []);

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 1650 }, items: 5 },
    desktop: { breakpoint: { max: 1650, min: 1250 }, items: 4 },
    tablet: { breakpoint: { max: 1250, min: 464 }, items: 3 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 2 }
  };

  if(producteurName && producteurName=="NO" ) return <Navigate to="/" />

  return (
    <>
      <Helmet>
        <title>ReCut{producteurName?" - "+producteurName:""}</title>
        <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
        <meta property="og:title" content="ReCut" />
        <meta property="og:description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
        <meta property="og:image" content="https://recut.fr/images/logo-recut.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ReCut" />
      </Helmet>
      <div className="w-full">
        <div className="flex flex-col items-center gap-2.5">
          <div className="relative self-stretch">
            <div className="flex w-full flex-col items-center">
              <div className="flex items-center self-stretch md:flex-col">
                <div className="relative w-[100%] md:h-auto md:w-full">
                  <div className="w-full">
                    <div className="bg-cover bg-no-repeat pb-[55px] md:pb-5">
                      <div className="flex flex-col">
                        <AdminGestionPageDistributeurNavbar text={"La communauté "+producteurName} />
                        <div className="container-xs md:p-5">
                          <div className="flex flex-col items-center gap-[33px]">
                            <Img 
                            src={"/images/icons/"+producteur+".png"}
                            className={"w-[70px] h-[70px] object-contain rounded-full bg-[#ffffff] border-["+producteurColor+"] border-2"}
                            style={{borderColor:producteurColor}}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-[-37px] flex flex-col items-center bg-no-repeat p-[11px] md:h-auto">
                      <div className="w-[91%] md:w-full">
                        <div className="container-xs flex flex-col items-start gap-[27px] md:p-5">
                          <div className="flex flex-col w-[100%]">
                            <div className="container-xs flex flex-col items-start">
                              <Heading size="headline_3" as="h2" className="text-[24px]">
                                LES CHALLENGES CRÉATEURS
                              </Heading>
                            </div>
                            <div className="container-xs">
                              <div className="flex mt-3 w-[100%] mb-5 gap-5 sm:w-[100%] sm:flex-row sm:overflow-scroll">
                                {Array.isArray(films) && films.length > 0 ? (
                                  <div style={{ width: '100%', maxWidth: '100%', margin: '0 auto' }}>
                                    <Carousel responsive={responsive}>
                                      {films.map((film, index) => (
                                        // Vérification que film.producteur et film.pseudo existent
                                        film && film.producteur && film.pseudo ? (
                                          <Link to={"/" + film.producteur + "/" + film.pseudo} className="w-[200px] sm:w-[150px]" key={index}>
                                            <div className="mb-3">
                                              <Img
                                                src={appUrl + "public/films/" + film.id + "-affiche.jpg"}
                                                alt={film.title}
                                                className="h-[300px] sm:h-[250px] w-[200px] sm:w-[150px] object-cover rounded-[20px]"
                                              />
                                            </div>
                                            <div className="flex flex-1 flex-col items-start gap-2 sm:gap-1">
                                              <Heading size="subtitle_3" as="h3" className="sm:text-sm limit-texte">
                                                {film.title}
                                              </Heading>
                                              <Text as="p" className="!text-gray-500 sm:text-xs limit-texte">
                                                {film.sequenceCount} séquences
                                              </Text>
                                            </div>
                                          </Link>
                                        ) : (
                                          <Text key={index} as="p" className="text-white sm:text-xs">Film data unavailable</Text>
                                        )
                                      ))}
                                    </Carousel>
                                  </div>
                                ) : (
                                  <Text as="p" className="text-white sm:text-xs">No films available</Text>
                                )}
                                {dataFilmsLoading &&
                                  <div className="flex items-center w-[100%] justify-center"><Img src="/images/icons/loading.gif" className="w-[50px] h-[50px]"/></div>
                                }
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col w-[100%] ">
                            <div className="container-xs flex flex-col items-start grid grid-cols-3 gap-4">
                              <Heading size="headline_3" as="h2" className="col-span-2 sm:col-span-8 text-[24px] mb-[-5px]">
                              LES EXTRAITS À CLIPER
                              </Heading>
                            </div>
                            {Array.isArray(listeVideos) && listeVideos.length > 0 ? (
                              listeVideos.map((pageVideos, index) => (
                                <HomeColumntime key={index} videos={pageVideos} />
                              ))
                            ) : (
                              <Text as="p" className="text-white sm:text-xs">No videos available</Text> 
                            )}
                            {dataLoading &&
                            <div className="flex items-center w-[100%] justify-center"><Img src="/images/icons/loading.gif" className="w-[50px] h-[50px]"/></div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
