import { Heading } from "components";
import AdminGestionPageDistributeurNavbar from "components/AdminGestionPageDistributeurNavbar";
import Footer from "components/Footer";
import React, { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getFilm } from "services/filmsServices";

export default function ConditionsGeneralesUtilisation() {
    const location = useLocation();
    const [title, setTitle] = useState("#nomdufilm"); 
    const [producteur, setProducteur] = useState("Pathé");
    const [filmData, setFilmData] = useState(null);
    const searchParams = new URLSearchParams(location.search);

    const fetchFilm = async () => {
        try {
            const challenge = searchParams.get('challenge');
            if (!challenge) {
                console.log("Aucun challenge trouvé dans l'URL");
                return;
            }

            let filmData = await getFilm(challenge);
            if(filmData){
                filmData = filmData.film
                setFilmData(filmData);
                if (filmData && filmData.title) {
                    setTitle(`#${filmData.title.replace(" ","")}`);
                    if(filmData.challenge.title!=""){
                        setTitle(filmData.challenge.title);
                    }
                } else {
                    console.log("Titre de film non disponible, affichage du titre par défaut");
                }
    
                if (filmData && filmData.producteur) {
                    if (filmData.producteur.toLowerCase() === "pathe") {
                        setProducteur("Pathé");
                    } else if (filmData.producteur.toLowerCase() === "recut" && filmData.pseudo === "paulwatson") {
                        setProducteur("Galatée Films et Sea Shepherd");
                    }else if (filmData.producteur.toLowerCase() === "studiocanal") {
                        setProducteur("TRESOR FILMS - CHI-FOU-MI PRODUCTIONS – STUDIOCANAL");
                    }
                    else if (filmData.producteur.toLowerCase() === "tandem") {
                        setProducteur("Nord-Ouest Production / Tandem Films");
                    }
                } else {
                    console.log("Producteur non disponible, affichage du producteur par défaut");
                }
            }
           
        } catch (err) {
            console.log("Erreur lors du chargement des données du film.", err);
        }
    };

    useEffect(() => {
        fetchFilm();
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <>
        <Helmet>
            <title>ReCut</title>
            <meta name="description" content="Visionnage, téléchargement et utilisation des contenus mis à disposition sur la plateforme ReCut." />
        </Helmet>
        <div className="w-full">
            <div className="flex flex-col items-center gap-2.5">
                <div className="relative self-stretch ">
                    <div className="flex w-full flex-col items-center">
                        <div className="flex items-center self-stretch md:flex-col">
                            <div className="relative w-[100%] md:h-auto md:w-full">
                                <div className="w-full">
                                    <div className="flex flex-col items-center gap-[289px] md:gap-[216px] sm:gap-36">
                                        <AdminGestionPageDistributeurNavbar /> 
                                    </div>
                                </div>
                                <div className="container-xs">
                                    {filmData?
                                    <> 
                                    <Link to={"/"+filmData.producteur+"/"+filmData.pseudo+""} className="mb-[20px]"><Heading size="headline_6" as="h6">&lt; CHALLENGE</Heading></Link>
                                    <div className="mx-auto p-6 mb-6 bg-gray-900 text-default-white rounded-lg" dangerouslySetInnerHTML={{ __html: filmData.conditionsgeneralesutilisation }} />
                                    </>:
                                    <div className="mx-auto p-6 mb-6 bg-gray-900 text-default-white rounded-lg">
                                        <h1 className="text-3xl font-bold mb-4">Conditions Générales d'utilisation ReCut</h1>

                                        <h2 className="text-2xl font-semibold mb-4">SYNTHESE DES CONDITIONS</h2>
                                        <p className="mb-4"><strong>Titulaire de droits :</strong> {producteur}</p>
                                        <p className="mb-4"><strong>Droits consentis :</strong> édit au format TikTok / Instagram / Facebook et/ou YouTube</p>
                                        <p className="mb-4"><strong>Mentions à apposer :</strong> à compléter © ….</p>

                                        <h2 className="text-2xl font-semibold mb-4">CONDITIONS D’UTILISATION ET LICENCE SUR LES EXTRAITS</h2>
                                        <p className="mb-4"><strong>Date d’entrée en vigueur :</strong> 24 juin 2024</p>
                                        <p className="mb-4">
                                            VEUILLEZ TROUVER CI-DESSOUS LES CONDITIONS GÉNÉRALES D'UTILISATION ET DE LICENCE RÉGISSANT :
                                        </p>
                                        <ul className="list-disc list-inside mb-4">
                                            <li>L’accès à la plateforme Recut</li>
                                            <li>L’usage des fonctionnalités de la plateforme Recut, le visionnage, le téléchargement ou l’utilisation des contenus mis à disposition sur la plateforme Recut, dont les droits sont détenus par les ayants droit partenaires de Recut.</li>
                                        </ul>
                                        <p className="mb-4">
                                            En accédant à la plateforme Recut et en utilisant ou téléchargeant le contenu proposé, vous acceptez l’ensemble des termes du présent contrat et devenez Utilisateur, au sens du présent contrat (« le Service »).
                                        </p>

                                        <h2 className="text-2xl font-semibold mb-4">Article 1 – Licence d’accès au Service</h2>
                                        <p className="mb-4">
                                            Vous bénéficiez d’une licence limitée, non exclusive, non transférable, non sous-licenciable et personnelle pour accéder et utiliser le Service, sous réserve que cette licence soit soumise à votre conformité à ces conditions générales. Si un contenu ou autre matériel détenu par, contrôlé par ou concédé sous licence est distribué ou mis à votre disposition dans le cadre de votre utilisation du Service, nous vous accordons par la présente une licence personnelle, non commerciale, non assignable, non sous-licenciable, non transférable et non exclusive pour accéder, consulter, afficher et utiliser ce contenu, dans le seul but de vous permettre d'utiliser le Service, tel que défini par ces conditions générales.
                                        </p>
                                        <p className="mb-4">
                                            Recut peut supprimer ou retirer ou restreindre l’usage de tout contenu, à tout moment, à sa seule et absolue discrétion.
                                        </p>
                                        <p className="mb-4">
                                            En utilisant le Service, vous acceptez de ne pas violer de loi, contrat, droit de propriété intellectuelle ou autre droit de tiers, et que vous êtes seul responsable de votre accès ou votre utilisation du Service.
                                        </p>
                                        <p className="mb-4">
                                            Vous acceptez également de ne pas :
                                        </p>
                                        <ul className="list-disc list-inside mb-4">
                                            <li>utiliser ou tenter d’utiliser le compte Recut d’un autre Utilisateur,</li>
                                            <li>vous faire passer pour une autre personne ou entité,</li>
                                            <li>distribuer des spam,</li>
                                            <li>faire une utilisation du Service qui pourrait endommager, désactiver, surcharger ou altérer le fonctionnement du Service de quelque manière que ce soit,</li>
                                            <li>ignorer ou contourner les instructions qui contrôlent l’accès au Service,</li>
                                            <li>utiliser tout data mining, robot, spider, crawler, scraper, script, extension de navigateur, lecteur hors ligne ou autre moyen automatisé ou interface non autorisé par nous pour accéder au Service, extraire des données ou autrement interférer avec ou modifier le rendu des pages du Service ou les fonctionnalités,</li>
                                            <li>décompiler, désassembler ou décoder toute partie du Service, ou faire un acte susceptible de divulguer du code source ou contourner ou désactiver les mesures employées pour prévenir ou limiter l’accès au Service,</li>
                                            <li>vendre ou revendre le Service,</li>
                                            <li>utiliser le Service ou les données collectées à partir de notre Service pour toute activité publicitaire ou de marketing direct,</li>
                                            <li>utiliser le Service pour tout acte contraire à la loi ou pour violer ou porter atteinte aux droits de propriété intellectuelle ou tout autre droit d’un tiers,</li>
                                            <li>utiliser ou afficher le Service pour créer du contenu illégal.</li>
                                        </ul>

                                        <h2 className="text-2xl font-semibold mb-4">Article 2 - L’utilisation du contenu par l’Utilisateur</h2>
                                        <p className="mb-4">
                                            Les différents contenus proposés peuvent être utilisés, par l’Utilisateur, conformément aux conditions d’accès et droits accordés pour chaque contenu spécifique et sous réserve du respect des conditions de la présente licence.
                                        </p>
                                        <p className="mb-4">
                                            Le terme « utiliser » désigne le fait, pour l’utilisateur, de télécharger, copier, reproduire, modifier, éditer, synchroniser, diffuser ou publier le contenu proposé par Recut, sur les réseaux sociaux personnels de l’utilisateur, afin de créer des User Generated Contents « UGC ».
                                        </p>
                                        <p className="mb-4">
                                            Les contenus proposés peuvent être utilisés par l’Utilisateur, pour la création d’UGC, qui pourront être diffusés pour la durée légale des droits d’auteur, pour le monde entier et pour une exploitation sur les pages personnelles ou officielles des réseaux sociaux de l’Utilisateur uniquement (à savoir TikTok, Instagram, Facebook ou Youtube), à l’exclusion de tout autre support.
                                        </p>
                                        <p className="mb-4">
                                            La licence accordée pour ces contenus à l’Utilisateur est non exclusive et non transférable. Recut se réserve le droit d’accorder des licences portant sur le même contenu à d’autres utilisateurs/clients. L’Utilisateur ne peut consentir de sous-licence à des tiers. L’usage est strictement réservé à l’Utilisateur.
                                        </p>
                                        <p className="mb-4">
                                            L’Utilisateur s’engage, pour chaque création d’UGC, de publier les éléments suivants, concomitamment à la mise en ligne du contenu :
                                        </p>
                                        <ul className="list-disc list-inside mb-4">
                                            <li>{title}</li>
                                            <li>@recut.art</li>
                                            <li>Crédits de titulaire de droits, tels que définis sur chaque contenu.</li>
                                        </ul>
                                        <p className="mb-4">
                                            Vous déclarez et garantissez que vous avez, ou avez obtenu, tous les droits, licences, consentements, permissions, pouvoir et/ou autorité nécessaires pour exploiter tout UGC contenant un contenu utilisé par le biais du Service.
                                        </p>

                                        <h2 className="text-2xl font-semibold mb-4">Article 3 – Restrictions d’utilisation des contenus</h2>
                                        <p className="mb-4">
                                            Aucune utilisation illégale ne sera acceptée. L’Utilisateur s’engage à un usage des contenus comme suit :
                                        </p>
                                        <ul className="list-disc list-inside mb-4">
                                            <li>Pas d’utilisation illégale. Vous ne pouvez pas utiliser de contenu à des fins pédocriminelles, pornographiques, de promotion du suicide ou de l’automutilation, pour créer des « deepfake », pour inciter à la violence ou la haine, pour banaliser des comportements délictuels, pour entraîner une plateforme d’intelligence artificielle, dans des prompts ou plus généralement, en violation de toute réglementation en vigueur ou de tout code professionnel.</li>
                                            <li>Pas de modification de contenu en dehors du contexte. Le contenu peut être recadré ou autrement modifié pour des raisons de qualité technique, ou associé à des contenus originaux de l’Utilisateur (« UGC ») à condition que l’intégrité éditoriale du contenu ne soit pas compromise. L’intégrité du contenu devra en aucun cas être altérée.</li>
                                            <li>Pas d’utilisation en tant que fichier autonome. Vous ne pouvez pas utiliser le contenu de façon permettant à d’autres de télécharger, extraire ou redistribuer le contenu en tant que fichier autonome (c’est-à-dire uniquement le fichier du contenu lui-même, séparé du projet ou de l’utilisation finale) ni utiliser de contenu en relation avec un fichier numérique immuable destiné à la vente ou à être distribué d’une autre façon (par exemple un jeton non fongible - NFT).</li>
                                            <li>Pas d’utilisation commerciale. Vous ne pouvez pas utiliser le contenu en association avec des marques commerciales, de publireportage, de parrainage, de publicité, de jeux d’argent, paris ou gaming, ou de merchandising ou dans une finalité promotionnelle pour la promotion de produits et services de tiers.</li>
                                            <li>Pas de fausse déclaration de paternité d’œuvre. Vous ne pouvez pas déclarer que vous êtes le créateur original du contenu.</li>
                                            <li>Pas d’utilisation au terme de la période de licence. Vous ne pouvez pas réutiliser un contenu qui n’est plus accessible sur Recut, ni même republier tout ou partie d’un UGC créé sur la base d’un contenu.</li>
                                            <li>Pas d’utilisation sensible ou polémique. Vous ne pouvez pas utiliser les contenus pour créer des UGC ouvertement polémiques, critiques, sensibles ou portant atteinte à la dignité d’un individu ou d’un groupe de personnes ou aux bonnes mœurs.</li>
                                            <li>Pas d’utilisation dans une marque ou un logo. Vous ne pouvez pas utiliser le contenu (en tout ou en partie) comme élément distinctif d’une marque, d’un dessin-marque, d’un nom commercial, d’une raison sociale, d’une marque de service ou d’un logo. De plus, vous n’avez pas le droit d’enregistrer un tel contenu (en tout ou en partie) en tant que marque ou copyright.</li>
                                            <li>Pas d’utilisation dans Machine Learning, Intelligence Artificielle ou de technologie biométrique. Vous ne pouvez pas utiliser le contenu (y compris les informations de légende, les mots-clés ou autres métadonnées associées au contenu) à des fins d’apprentissage machine et/ou d’intelligence artificielle, ou pour toute technologie conçue ou destinée à l’identification des personnes physiques ni permettre à des tiers d’accéder à ou d’utiliser ces informations associées au contenu.</li>
                                        </ul>
                                        <p className="mb-4">
                                            Recut se réserve le droit de supprimer tout UGC qui enfreindrait les droits d’un tiers, restreignant ainsi l'accès ou la visibilité de l’UGC. Vous reconnaissez que toute opération que vous effectuez par la plateforme Recut est réalisée avec la connaissance et l'acceptation du risque que le contenu puisse ensuite être retiré de la plateforme. Recut ne pourra en aucun cas être tenu responsable envers vous à cet égard.
                                        </p>

                                        <h2 className="text-2xl font-semibold mb-4">Article 4 – Propriété</h2>
                                        <p className="mb-4">
                                            Le Service, y compris le graphisme de la plateforme Recut, l’ensemble des contenus, informations et autres éléments et tous les œuvres audiovisuelles, designs, textes, graphiques, images, données, logiciels, fichiers sonores, autres fichiers, et la sélection et l'arrangement de ceux-ci sont la propriété exclusive de Recut et de ses partenaires, concédants ou utilisateurs. Nous et nos concédants et utilisateurs, selon le cas, nous réservons tous les droits en relation avec le Service et son contenu, y compris, sans limitation, le droit exclusif de créer des œuvres dérivées.
                                        </p>
                                        <p className="mb-4">
                                            Le nom Recut, le logo, les marques de commerce et tout nom de produit ou service, design, logo et slogans sont la propriété intellectuelle de Recut ou de nos partenaires ou concédants et ne peuvent être copiés, imités ou utilisés, en tout ou en partie, sans notre autorisation écrite préalable. La référence à tout contenu, au Service, ou autre information par nom, marque de commerce, distributeur, fournisseur ou autre ne constitue ni n'implique un soutien, parrainage ou recommandation de la part de Recut, de ses partenaires ou utilisateurs.
                                        </p>

                                        <h2 className="text-2xl font-semibold mb-4">Article 5 – Compte Utilisateur</h2>
                                        <p className="mb-4">
                                            Vous déclarez être majeur dans la juridiction dans laquelle vous résidez, pour conclure un contrat (et en tout état de cause, êtes âgé de plus de treize (13) ans) et que vous avez pleine autorité légale pour conclure et exécuter cet accord et donner ces déclarations et garanties. Si vous êtes une personne morale, vous déclarez que la personne agissant en votre nom est autorisée à le faire et à engager la personne morale.
                                        </p>
                                        <p className="mb-4">
                                            Recut peut résilier ces conditions générales, annuler toute opération, résilier ou suspendre votre accès à tout ou partie du Service à tout moment, et/ou bloquer ou empêcher tout accès futur à et utilisation du Service, avec ou sans motif, avec ou sans préavis et avec effet immédiat. Lors de toute résiliation, tous les droits et obligations des parties aux présentes cesseront et vous cesserez immédiatement d’utiliser le Service.
                                        </p>
                                        <p className="mb-4">
                                            Recut est seule destinataire des informations nominatives fournies par les Utilisateurs.
                                        </p>
                                        <p className="mb-4">
                                            Les Utilisateurs sont informés que leurs coordonnées feront l’objet d’une collecte et d’un traitement informatique pour la gestion du Service. Conformément à la loi Informatique et Liberté n°78-17 du 06 janvier 1978 modifiée et au Règlement Général pour la Protection des Données (RGPD) n° 2016/679 du 27 avril 2016, les Utilisateurs disposent d’un droit d’accès, de rectification et d’opposition des informations nominatives les concernant qu’ils peuvent exercer sur simple demande à : <a href="mailto:dpo@recut.fr" className="text-blue-500 underline">dpo@recut.fr</a> Les données collectées seront conservées pendant un délai de 5 (cinq) ans à compter de la résiliation du compte Utilisateur.
                                        </p>
                                        <p className="mb-4">
                                            Les données pourront être communiquées aux partenaires, prestataires et sous-traitants auxquels Recut ferait éventuellement appel pour les besoins de l’organisation et la gestion du Service. Recut met en place les moyens techniques nécessaires pour conserver les données, dans les conditions de sécurité requises par la réglementation applicable. Dans la mesure où les données collectées concernant les Utilisateurs sont indispensables à l’utilisation du Service, l’Utilisateur est informé que le refus de partager ses données ou l’exercice de son droit de retrait du consentement ou d’effacement des données entraine l’impossibilité ou l’annulation de son accès au Service, au contenu, à son compte et la suspension des Services.
                                        </p>
                                        <p className="mb-4">
                                            Le Service utilise des cookies (témoins de connexion) ce dont l’Utilisateur est informé en arrivant sur le site qui permettent d’enregistrer des informations relatives à la navigation de l’Utilisateur sur le site. Ces cookies ne sont installés qu’après acceptation par l’Utilisateur, la poursuite de la navigation sur le site vaut acceptation. L’Utilisateur peut s’opposer à l’utilisation de ces cookies en paramétrant son navigateur, sachant que l’accès à certaines parties du Service peut nécessiter l’acceptation préalable des cookies par l’Utilisateur.
                                        </p>

                                        <h2 className="text-2xl font-semibold mb-4">Article 6 – Responsabilité</h2>
                                        <p className="mb-4">
                                            Le Service, l’accès à la Plateforme et aux contenus nécessitent un accès internet. Recut s’engage à fournir ses meilleurs efforts afin d’assurer un accès permanent au Service, ainsi que son bon fonctionnement. Recut se réserve la possibilité d’interrompre l’accès au Service pour des raisons de maintenance. Recut ne peut être tenu responsable de l’indisponibilité du Service, notamment en cas de : (i) maintenance évolutive ou corrective, y compris lorsque l’intervention de maintenance est réalisée par des tiers ; (ii) survenance d’un évènement de force majeure tel que défini par les juridictions, (iii) défaillance du réseau internet ou (iv) de l’équipement de l’Utilisateur.
                                        </p>
                                        <p className="mb-4">
                                            L’Utilisateur est par ailleurs informé des risques inhérents à l’utilisation d’internet, et tout particulièrement en termes de défaut de sécurité dans la transmission des données et de continuité non garantie dans l’accès au Site et aux contenus. En aucun cas, Recut ne saurait être tenu responsable de ces risques et de leurs conséquences, préjudiciables, quelle qu’en soit l’étendue, pour l’Utilisateur.
                                        </p>
                                        <p className="mb-4">
                                            Le contenu est fourni en l’état, sans garantie ni conditions d’aucune sorte.
                                        </p>
                                        <p className="mb-4">
                                            Recut et ses partenaires, leurs filiales et leurs employés, administrateurs, dirigeants, agents, représentants, actionnaires, consultants, partenaires, contractants, fournisseurs de contenu, déclinent toute garantie, condition, et déclaration, expresse ou implicite, statutaire ou autre, y compris les garanties ou conditions implicites de conformité des contenus, d’adéquation à un usage particulier et ne font aucune déclaration ni garantie que les contenus présents sur Recut, seront disponibles de manière ininterrompue, opportune, sécurisée, ou sans erreur.
                                        </p>
                                        <p className="mb-4">
                                            Recut et ses partenaires, leurs filiales et leurs employés, administrateurs, dirigeants, agents, représentants, actionnaires, consultants, partenaires, contractants, fournisseurs de contenu ne seront responsables des dommages, pertes de profits, revenus ou données, des pertes financières ou des dommages indirects, spéciaux, consécutifs, découlant d’un accès au Service ou de l’utilisation des contenus.
                                        </p>

                                        <h2 className="text-2xl font-semibold mb-4">Article 7 – Droit applicable et juridiction</h2>
                                        <p className="mb-4">
                                            Ces conditions générales sont rédigées conformément au droit français. Tout litige relatif à l’accès au Service, aux contenus disponibles sur Recut et/ou à l’interprétation de ces conditions générales sera soumis à la juridiction exclusive des tribunaux compétents de Paris.
                                        </p>
                                    </div>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    );
}