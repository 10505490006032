import { Link } from "react-router-dom";
import { Text, Heading, Button, Img } from "./..";
import React, { useEffect, useState } from "react";
import { downloadSequenceClip } from "services/sequencesServices";
import axios from 'axios';
import { getDureeUserSequences } from "services/userService";
import { createMediaContainer, publishMediaContainer } from 'services/authServices';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import ClipTimeline from "components/ClipTimeline";
export default function FilmClipSequence({
  idsequence = "0",
  filmId = "0",
  title = "",
  timesequence= "00:00",
  setDataTimeClipVideo,
  setDisabledClipVideo,
  maxDureeSequence=30,
  maxDureeClips=180,
  ...props
}) {
  const appUrl = process.env.REACT_APP_URL;
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupPartager, setShowPopupPartager] = useState(false);
  const [formatPartager, setFormatPartager] = useState(null);
  const [showPopupPartagerSociaux, setShowPopupPartagerSociaux] = useState(false); 
  const [isLoading, setIsLoading] = useState(false);
  const [range, setRange] = useState([0,maxDureeSequence]);
  const [timeStart, setTimeStart] = useState('00:00');
  const [timeEnd, setTimeEnd] = useState('00:'+maxDureeSequence);
  const [outputName, setOutputName] = useState(title);
  const [error, setError] = useState('');
  const [accessToken, setAccessToken] = useState(''); // Set your access token here
  const [userId, setUserId] = useState('845728473808793');
  const [caption, setCaption] = useState('Légende');
  const [thumbnails, setThumbnails] = useState([]);
  const [statsdure, setStatsdure] = useState(true);
  const [restdureeSequence, setRestdureeSequence] = useState(maxDureeSequence);
  const [dureeClipSequence, setdureeClipSequence] = useState(maxDureeSequence);
  const [dureeMaxDureeClipSequence, setdureeMaxDureeClipSequence] = useState(maxDureeSequence);
  const [dureeMaxDureeClips, setdureeMaxDureeClips] = useState(maxDureeClips);
  useEffect(() => {
    generateThumbnails();
    generateFetchData();
    
  }, [idsequence]);

  const generateFetchData = async () => {
    try {
      console.log("START dureeSequence");
      const dataduree = await getDureeUserSequences(idsequence,filmId);
      const dureeSequence = dataduree.dureeSequences;
      setdureeMaxDureeClipSequence(dataduree.maxclipsequence?dataduree.maxclipsequence:dureeMaxDureeClipSequence);
      setdureeMaxDureeClips(dataduree.maxclip?dataduree.maxclip:dureeMaxDureeClips)
      console.log("dureeSequence",dureeSequence);
      let restduree = (dureeSequence>=dureeMaxDureeClipSequence)?maxDureeSequence:dureeMaxDureeClipSequence-dureeSequence;
      restduree = (restduree>convertTimeStringToSeconds(timesequence))?convertTimeStringToSeconds(timesequence):restduree;
      setRestdureeSequence(restduree);
      
      setRange([0,restduree])
      if(restduree>60){
        let minutes = Math.floor(restduree / 60); // Nombre entier de minutes
        if(minutes<10) minutes = "0"+minutes;
        let seconds = restduree % 60; // Restant en secondes
        if(seconds<10) seconds = "0"+seconds;
        setTimeEnd(minutes+":"+seconds)
      }else{
        setTimeEnd("00:"+restduree)
      }
      afficherMsgErrorAndUpdateStatusdure(restduree,"00:00","00:"+restduree);
      setDataTimeClipVideo({timeStart:0,timeEnd:convertTimeStringToSeconds("00:"+restduree)})
      
    } catch (error) {
      setRestdureeSequence(dureeMaxDureeClipSequence);
      console.error("Error fetching data:", error);
    }
    
    //afficherMsgErrorAndUpdateStatusdure(restdureeSequence);
   
  };

  const generateThumbnails = () => {
    const thumbnails = [];
    for (let i = 1; i <= 10; i++) {
      thumbnails.push(appUrl+"public/frames/sequence_"+idsequence+"/frame-"+i+".png");
    }
    setThumbnails(thumbnails);
  };

  const padTime = (time) => {
    let [minutes, seconds] = time.split(':');
    if (minutes.length < 2) minutes = '0' + minutes;
    if (seconds.length < 2) seconds = '0' + seconds;
    return `${minutes}:${seconds}`;
  };

  const handleButtonDownloadClick = async (format) => {
    
    setIsLoading(true);
    setShowPopup(false);
    try {
        const response = await downloadSequenceClip(idsequence, padTime(timeStart), padTime(timeEnd), outputName,format,false);
        const blob = new Blob([response.data], { type: 'video/mp4' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${title}_${outputName}_${format}.mp4`;
        link.click();
        generateFetchData();
    } catch (error) {
        console.error('Error downloading video clip:', error);
        if (error.response) {
            if (error.response.status === 400 && error.response.data) {
                console.error('Error 400:', error.response.data);
                setError("Un problème est survenu !");
            } else {
                console.error('Server responded with error:', error.response.data);
                setError("Un problème est survenu !");
            }
        } else if (error.request) {
            console.error('No response received:', error.request);
            setError("Un problème est survenu !");
        } else {
            console.error('Error setting up request:', error.message);
            setError("Un problème est survenu !");
        }
    } finally {
        setIsLoading(false);
    }
  };

  const handleButtonClick = async (status) => {
    setError('');

    // Convert timeStart and timeEnd to seconds
    const startSeconds = convertToSeconds(timeStart);
    const endSeconds = convertToSeconds(timeEnd);
    console.log("timeStart",timeStart);
    console.log("timeEnd",timeEnd);
    console.log("startSeconds",startSeconds);
    console.log("endSeconds",endSeconds);
   
    

    // Check if timeStart is greater than timeEnd
    if (timeStart === "" || timeEnd === "") {
      setError(" L'heure de début et l'heure de fin doivent être renseignées.");
      return;
    }
    // Check if timeStart is greater than timeEnd
    if (startSeconds >= endSeconds) {
      setError("L'heure de début ne peut pas être postérieure à l'heure de fin.");
      return;
    }

    // Check if duration exceeds 30 seconds
    const duration = endSeconds - startSeconds;
    if (duration > dureeMaxDureeClipSequence) {
      setError('Le clip ne peut pas dépasser '+dureeMaxDureeClipSequence+' secondes.');
      return;
    }

    const dataduree = await getDureeUserSequences(idsequence,filmId);
    const dureeSequences = dataduree.dureeSequences;
    const dureeSequence = dataduree.dureeSequence;

    if (dureeSequence>=dureeMaxDureeClipSequence) {
      setError("Vous avez atteint la limite de téléchargement de "+dureeMaxDureeClipSequence+" secondes pour cette séquence. Vous pouvez télécharger vos clips dans d'autres formats dans l'espace 'Mes séquences'.");
      return;
    }
    console.log("//////////////////////////////////////FIN ----FIN//////////////////////////////////////////");
    if (dureeSequences>=dureeMaxDureeClips) {
      setError("Vous avez atteint la limite de téléchargement de "+dureeMaxDureeClips+" secondes.");
      return;
    }
    if ((dureeSequences + duration)>dureeMaxDureeClips) {
      const restes = dureeMaxDureeClips-dureeSequences;
      setError("Le clip ne doit pas dépasser "+restes+" secondes.");
      return;
    }
    
    // if ((dureeSequence + duration)>30) {
    //   const reste = 30-dureeSequence;
    //   setError("Le clip ne doit pas dépasser "+reste+" secondes.");
    //   return;
    // }
    

    // Check if outputName is empty
    if (!outputName) {
      setError('Le titre ne peut pas être vide.');
      return;
    }
    if(status=="partager"){
      setShowPopupPartager(!showPopupPartager);
      setShowPopupPartagerSociaux(false);
    }else{
      setShowPopup(!showPopup);
      setShowPopupPartagerSociaux(false);
    }
   
  };

  const convertToSeconds = (time) => {
      const [minutes, seconds] = time.split(':').map(Number);
      return minutes * 60 + seconds;
  };

  const handleMouseLeave = () => {
    setShowPopup(false);
    setShowPopupPartager(false);
    setShowPopupPartagerSociaux(false);
  };

  const handleInputChangeTimeStart = (e) => {
    let value = e.target.value;
    value = value.replace(/[^\d:]/g, '');

    if (value.length === 2 && !value.includes(':')) {
        value = value + ':';
    }

    if (value.length > 5) {
        value = value.slice(0, 5);
    }

    setTimeStart(value);
    setRange([convertTimeStringToSeconds(value),convertTimeStringToSeconds(timeEnd)]);
    afficherMsgErrorAndUpdateStatusdure(restdureeSequence,value,timeEnd);
    setDataTimeClipVideo({timeStart:convertTimeStringToSeconds(value),timeEnd:convertTimeStringToSeconds(timeEnd)});
  };

  const handleInputChangeTimeEnd = (e) => {
    let value = e.target.value;
    value = value.replace(/[^\d:]/g, '');

    if (value.length === 2 && !value.includes(':')) {
        value = value + ':';
    }

    if (value.length > 5) {
        value = value.slice(0, 5);
    }

    setTimeEnd(value);
    setRange([convertTimeStringToSeconds(timeStart),convertTimeStringToSeconds(value)]);
    afficherMsgErrorAndUpdateStatusdure(restdureeSequence,timeStart,value);
    setDataTimeClipVideo({timeStart:convertTimeStringToSeconds(timeStart),timeEnd:convertTimeStringToSeconds(value)});
  };

  
  const handleButtonPartagerClick = async (format) => {
    setShowPopupPartagerSociaux(true);
    setShowPopupPartager(true);
    setFormatPartager(format);
    setShowPopup(false);
   
  };

  function convertTimeToSeconds(timeString) {
    const [minutes, seconds] = timeString.split(':').map(Number);
    return (minutes * 60) + seconds;
  }

  const getPartagerFormat = () => {
    if(formatPartager == "vertical") return "Vertical";
    if(formatPartager == "horizontal") return "Horizontal";
    if(formatPartager == "square") return "Carré";   
    if(formatPartager == "original") return "Original";
  };

  const handleButtonPartagerSociauxClick = async (sociaux) => {
    
    setIsLoading(true);
    setShowPopup(false);
    setShowPopupPartager(false)
    setShowPopupPartagerSociaux(false);

    try {
        const response = await downloadSequenceClip(idsequence, padTime(timeStart), padTime(timeEnd), outputName,formatPartager,true);
        const videoUrl = response.data.url;
        console.log(response);
        if(sociaux == "tikTok"){  
          window.open(`https://www.tiktok.com/upload?url=${encodeURIComponent(videoUrl)}`, '_blank');
        }
        if(sociaux == "instagram"){
          // try {
          //   const containerId = await createMediaContainer(accessToken, userId, videoUrl, caption);
          //   const response = await publishMediaContainer(accessToken, userId, containerId);
          //   setMessage('Video publiée avec succès');
          // } catch (error) {
          //   setMessage('Erreur lors de la publication de la vidéo : ' + error.message);
          // }
          window.open(`https://www.instagram.com/?url=${encodeURIComponent(videoUrl)}`, '_blank');
        }
        if(sociaux == "fecebook"){
          window.open(`https://www.instagram.com/?url=${encodeURIComponent(videoUrl)}`, '_blank');
        }

    } catch (error) {
        console.error('Error downloading video clip:', error);
        if (error.response) {
            if (error.response.status === 400 && error.response.data) {
                console.error('Error 400:', error.response.data);
                setError("Un problème est survenu !");
            } else {
                console.error('Server responded with error:', error.response.data);
                setError("Un problème est survenu !");
            }
        } else if (error.request) {
            console.error('No response received:', error.request);
            setError("Un problème est survenu !");
        } else {
            console.error('Error setting up request:', error.message);
            setError("Un problème est survenu !");
        }
    } finally {
        setIsLoading(false);
    }
  };

  const handleRangeChange = (values) => {
    setRange(values);
    setTimeStart(convertSecondsToTimeString(values[0]));
    setTimeEnd(convertSecondsToTimeString(values[1]));
    afficherMsgErrorAndUpdateStatusdure(restdureeSequence,convertSecondsToTimeString(values[0]),convertSecondsToTimeString(values[1]));
    setDataTimeClipVideo({timeStart:values[0],timeEnd:values[1]});
  };

  
  const handleRangeChangeedEnd = (values) => {
    console.log("End")
    setDisabledClipVideo(false)
  };

  const handleRangeChangeedStart = (values) => {
    console.log("Start")
    setDisabledClipVideo(true)
  };
  
  const handleRangeDragEnd = (values) => {
    console.log("Range Drag End")
    //setDisabledClipVideo(true)
  };

  const afficherMsgErrorAndUpdateStatusdure = (restdure,thisTimeStart,thisTimeEnd) => {
    const startSeconds = convertToSeconds(thisTimeStart);
    const endSeconds = convertToSeconds(thisTimeEnd);
    // Check if duration exceeds 30 seconds
    const duration = endSeconds - startSeconds;
    console.log("duration",duration);
    console.log("restdureeSequence",restdure);
    setdureeClipSequence(duration);
    if(duration>restdure){
      setStatsdure(false);
      setError('Le clip ne peut pas dépasser '+restdure+' secondes.');
    }else{
      setStatsdure(true);
      setError('');
    }
  };

  const convertSecondsToTimeString = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  const convertTimeStringToSeconds = (timeString) => {
    const [minutes, seconds] = timeString.split(':').map(Number);
    return (minutes * 60) + seconds;
  };

  return (
    <div {...props} className={`${props.className} flex flex-col gap-[13px]`}>
      {isLoading && (
        <div className="loading-recut">
          <Img
            src="/images/loading.gif"
            alt="Loading"
            className="h-[200px] object-cover cursor-pointer"
          />
        </div>
      )}
      <div className="self-stretch container-blockFilmClipsquence">
        <div className="blockFilmClipsquence">
          <input
            placeholder="Ajouter un titre"
            className="titreclip"
            value={outputName}
            onChange={(e) => setOutputName(e.target.value)}
          />
          <div className="blockFilmClipTime">
            <input
              type="text"
              placeholder="00:00"
              className="timeclip"
              maxLength="5"
              value={timeStart}
              onChange={handleInputChangeTimeStart}
            />
            -
            <input
              type="text"
              placeholder="00:00"
              className="timeclip"
              maxLength="5"
              value={timeEnd}
              onChange={handleInputChangeTimeEnd}
            />
          </div>
          <div className="blockFilmClipDuree">
            <p>{dureeClipSequence} secondes</p>
          </div>
          <div className="blockFilmClipFrame">
            <div style={{ display: 'flex' }} className="friseClip">
              {thumbnails.map((thumbnail, index) => (
                <img key={index} src={thumbnail} alt={`frame-${index}`} style={{ 
                    width: '45px', 
                    height: '70px', 
                    marginRight: '0px',
                    objectFit:'cover',
                    objectPosition:'left'
                }} />
              ))}
            </div>
            <RangeSlider
              min={0}
              max={convertTimeToSeconds(timesequence)}
              value={range}
              onInput={handleRangeChange}
              onThumbDragEnd={handleRangeChangeedEnd}
              onThumbDragStart={handleRangeChangeedStart}
              onRangeDragEnd={handleRangeChangeedEnd}
              onRangeDragStart={handleRangeChangeedStart}
              className={statsdure?"validdure":"invaliddure"}
            />
          </div>
          
          {error && <div className="error-message">{error}</div>}
        </div>

        {/* <ClipTimeline videoUrl="http://localhost:3000/public/frames/sequence_1/1.mp4" thumbnails={thumbnails}/> */}
        
        <button className="btn-clip" onClick={() => handleButtonClick("download")} disabled={isLoading}>
          <Img
            src="/images/download.svg"
            alt="Download"
            className="h-[30px] sm:h-[30px] object-cover" 
          />
          Télécharger le clip
        </button>
        {/* <button className="btn-clip" onClick={() => handleButtonClick("partager")} disabled={isLoading}>
          <Img
            src="/images/icon-partager.svg"
            alt="Download"
            className="h-[30px] sm:h-[30px] object-cover" 
          />
          Partager le clip
        </button> */}
        
        {showPopup && (
          <div 
            className="absolute top-[-50px] w-[300px] left-0 mt-12 ml-12 p-4 bg-white shadow-lg rounded-lg z-50 bg-black-900"
            onMouseLeave={handleMouseLeave}
          >
            <Text as="h1">Téléchargement</Text>
            <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonDownloadClick("vertical")}>
              <Text as="p">Vertical</Text>
              <Img
                src="/images/icons-social-tiy.svg"
                alt="image"
                className="h-[20px] rounded-[7px] object-cover"
              />
              <div>
                <Img
                  src="/images/download.svg"
                  alt="image"
                  className="h-[20px] w-[20px] rounded-[7px] object-cover"
                />
              </div>
            </div>
            <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonDownloadClick("horizontal")}>
              <Text as="p">Horizontal</Text>
              <Img
                src="/images/icon-youtube.svg"
                alt="image"
                className="h-[20px] rounded-[7px] object-cover"
              />
              <div>
                <Img
                  src="/images/download.svg"
                  alt="image"
                  className="h-[20px] w-[20px] rounded-[7px] object-cover"
                />
              </div>
            </div>
            <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonDownloadClick("square")}>
              <Text as="p">Carré</Text>
              <Img
                src="/images/icons-social.svg"
                alt="image"
                className="h-[20px] rounded-[7px] object-cover"
              />
              <div>
                <Img
                  src="/images/download.svg"
                  alt="image"
                  className="h-[20px] w-[20px] rounded-[7px] object-cover"
                />
              </div>
            </div>
            <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonDownloadClick("original")}>
              <Text as="p">Original</Text>
              <div>
                <Img
                  src="/images/download.svg"
                  alt="image"
                  className="h-[20px] w-[20px] rounded-[7px] object-cover"
                />
              </div>
            </div>
          </div>
        )}

        {showPopupPartager && (
          <div 
            className="absolute top-[-50px] w-[300px] left-0 mt-12 ml-12 p-4 bg-white shadow-lg rounded-lg z-50 bg-black-900"
            onMouseLeave={handleMouseLeave}
          >
            <Text as="h1">Partager</Text>
            <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonPartagerClick("vertical")}>
              <Text as="p">Vertical</Text>
              <Img
                src="/images/icons-social-tiy.svg"
                alt="image"
                className="h-[20px] rounded-[7px] object-cover"
              />
              <div>
                <Img
                  src="/images/icon-partager.svg"
                  alt="image"
                  className="h-[20px] w-[20px] rounded-[7px] object-cover"
                />
              </div>
            </div>
            <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonPartagerClick("horizontal")}>
              <Text as="p">Horizontal</Text>
              <Img
                src="/images/icon-youtube.svg"
                alt="image"
                className="h-[20px] rounded-[7px] object-cover"
              />
              <div>
                <Img
                  src="/images/icon-partager.svg"
                  alt="image"
                  className="h-[20px] w-[20px] rounded-[7px] object-cover"
                />
              </div>
            </div>
            <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonPartagerClick("square")}>
              <Text as="p">Carré</Text>
              <Img
                src="/images/icons-social.svg"
                alt="image"
                className="h-[20px] rounded-[7px] object-cover"
              />
              <div>
                <Img
                  src="/images/icon-partager.svg"
                  alt="image"
                  className="h-[20px] w-[20px] rounded-[7px] object-cover"
                />
              </div>
            </div>
            <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonPartagerClick("original")}>
              <Text as="p">Original</Text>
              <div>
                <Img
                  src="/images/icon-partager.svg"
                  alt="image"
                  className="h-[20px] w-[20px] rounded-[7px] object-cover"
                />
              </div>
            </div>
            {showPopupPartagerSociaux ? (
              <>
                <Text className="mt-[20px]" as="h1">Partager la forma {getPartagerFormat()}</Text>
                <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonPartagerSociauxClick("fecebook")}>
                  <Img
                    src="/images/icon-facebook.svg"
                    alt="image"
                    className="h-[20px] rounded-[7px] object-cover"
                  />
                  <Text as="p">Fecebook</Text>
                  <div>
                    <Img
                      src="/images/icon-partager.svg"
                      alt="image"
                      className="h-[20px] w-[20px] rounded-[7px] object-cover"
                    />
                  </div>
                </div>
                <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonPartagerSociauxClick("instagram")}>
                  <Img
                    src="/images/icon-instagram.svg"
                    alt="image"
                    className="h-[20px] rounded-[7px] object-cover"
                  />
                  <Text as="p">Instagram</Text>
                  <div>
                    <Img
                      src="/images/icon-partager.svg"
                      alt="image"
                      className="h-[20px] w-[20px] rounded-[7px] object-cover"
                    />
                  </div>
                </div>
                <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonPartagerSociauxClick("tikTok")}>
                  <Img
                    src="/images/icon-tiktok.svg"
                    alt="image"
                    className="h-[20px] rounded-[7px] object-cover"
                  />
                  <Text as="p">TikTok</Text>
                  <div>
                    <Img
                      src="/images/icon-partager.svg"
                      alt="image"
                      className="h-[20px] w-[20px] rounded-[7px] object-cover"
                    />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
