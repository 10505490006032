import axios from 'axios';
import { logoutUser } from './services/authServices'; // Assurez-vous que cette fonction gère la déconnexion

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Le jeton est expiré, déconnectez l'utilisateur
      console.log(error.response);
      // Sauvegarde de l'URL actuelle dans localStorage
      const currentUrl = window.location.href; // Récupère l'URL actuelle
      localStorage.setItem('lastVisitedUrl', currentUrl); // Stocke l'URL dans localStorage
       
      logoutUser();
    }
    return Promise.reject(error);
  }
);


// Configuration globale d'Axios pour inclure le JWT dans les en-têtes d'autorisation
apiClient.interceptors.request.use(
    config => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
);

export default apiClient;