import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const apiUrl = process.env.REACT_APP_API_URL+"/auth";
const BASE_URL = 'https://graph.facebook.com/v14.0';
export async function login(email,password) {
  try {
    const response = await axios.post(apiUrl+'/login', { email, password });
    if(response.data.token && response.data.token!=""){
       localStorage.setItem('token', response.data.token);
        return true; 
    }
    
  } catch (error) {
    console.log('Error fetchData:', error);
    throw error;
  }
  return false;
}


export async function register(email,password) {
    try {
        const response = await axios.post(apiUrl+'/register', { email, password });
        if(response.data.token && response.data.token!=""){
            localStorage.setItem('token', response.data.token);
             return true; 
         }
    } catch (error) {
      console.log('Error fetchData:', error);
      throw error;
    }
}

export function InstagramLogin() { 
  window.location = apiUrl+'/instagram';
}

export function TikTokLogin() {
  window.location = apiUrl+'/tiktok';
}

export function GoogleLogin() {
  window.location = apiUrl+'/google';
}

export const logoutUser = () => {
  localStorage.removeItem('token'); // Ou tout autre stockage utilisé pour le jeton
  window.location = "/connexionseaux";
  return <Navigate to="/connexionseaux" /> // Redirigez vers la page de connexion
};

export const isAdmin = () => {
  if (localStorage.getItem('token')) {
    const decoded = jwtDecode(localStorage.getItem('token'));
    if(decoded.isadmin){
      return true;
    }else{
      return false; 
    }
  }else{
    return false; 
  }
};

export const getprofil = (defaultimg="") => {
  if (localStorage.getItem('token')) {
    const decoded = jwtDecode(localStorage.getItem('token'));
    if(decoded.profil){
      //return decoded.profil;
      return "/images/icons/icon-menu.svg";
    }else{
      // return defaultimg; 
      return "/images/icons/icon-menu.svg";
    }
  }else{
    return defaultimg; 
  }
};


export const isLogin = () => {
  const token = localStorage.getItem('token');
  
  if (token) {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Le temps actuel en secondes
      
      if (decoded.exp && decoded.exp < currentTime) {
        // Le token a expiré
        localStorage.removeItem('token'); // Optionnel : supprimer le token expiré
        return false;
      }
      
      return true;
    } catch (error) {
      console.error("Erreur lors de la décodage du token :", error);
      return false;
    }
  } else {
    return false;
  }
};

export const isLoginAvecRedirection = () => {
  if (localStorage.getItem('token')) {
    return true;
  }else{
    window.location = "/connexionseaux";
  }
};

export const createMediaContainer  = async (accessToken, userId, videoUrl, caption) => {
  const response = await axios.post(
    `${BASE_URL}/${userId}/media`,
    {
      media_type: 'VIDEO',
      video_url: videoUrl,
      caption: caption,
      access_token: accessToken,
    }
  );
  return response.data.id;
};

export const publishMediaContainer   = async (accessToken, userId, containerId) => {
  const response = await axios.post(
    `${BASE_URL}/${userId}/media_publish`,
    {
      creation_id: containerId,
      access_token: accessToken,
    }
  );
  return response.data;
};
