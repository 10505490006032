import React from "react";
import { useRoutes } from "react-router-dom";
import Browse from "pages/Browse";
import Film from "pages/Film";
import Editermonextrait from "pages/Editermonextrait";
import Mesinfospersonnelles from "pages/Mesinfospersonnelles";
import Crationcompteviarseaux from "pages/Crationcompteviarseaux";
import Connexion from "pages/Connexion";
import Register from "pages/Register";
import MesssequencessixPage from "pages/Messsequencessix";
import MentionslPage from "pages/Mentionsl";
import PrivacyPolicy from "pages/PrivacyPolicy";
import ConditionsGeneralesUtilisation from "pages/ConditionsGeneralesUtilisation";
import ConditionsGeneralesUtilisationRecut from "pages/ConditionsGeneralesUtilisationRecut";
import AdminStatistiquePage from "pages/AdminStatistique";
import AdminStatistiquePageFilm from "pages/AdminStatistique/film";
import AdminStatistiquePageSequence from "pages/AdminStatistique/sequence";
import NotFound from "pages/NotFound";
import PrivateRoute from "./PrivateRoute";
import PrivateAdminRoute from "./PrivateAdminRoute";
import AdminStatistiquePageUser from "pages/AdminStatistique/user";
import AdminStatistiquePageUserLogs from "pages/AdminStatistique/userlogs";
import AdminEmbargoPage from "pages/AdminEmbargo";
import AdminEmbargoPageUser from "pages/AdminEmbargo/user";
import AdminFilmAndExtrePage from "pages/AdminFilmAndExtre/index";
import AdminFilmAndExtrePageFilm from "pages/AdminFilmAndExtre/film";
import AdminFilmAndExtrePageExtre from "pages/AdminFilmAndExtre/extre";
import CommunautePage from "pages/Communaute";
import AdminFilmAndExtrePageFilmEdit from "pages/AdminFilmAndExtre/editfilm";

const ProjectRoutes = () => {
  const element = useRoutes([
    { path: "/", element: <Browse /> },
    // Routes Admin protégées
    { 
      path: "/admin/statistiques", 
      element: <PrivateAdminRoute><AdminStatistiquePage /></PrivateAdminRoute> 
    },
    { 
      path: "/admin/statistique/film/:id", 
      element: <PrivateAdminRoute><AdminStatistiquePageFilm /></PrivateAdminRoute> 
    },
    { 
      path: "/admin/statistique/film/:idfilm/sequence/:id", 
      element: <PrivateAdminRoute><AdminStatistiquePageSequence /></PrivateAdminRoute> 
    },
    { 
      path: "/admin/statistique/user/:id", 
      element: <PrivateAdminRoute><AdminStatistiquePageUser /></PrivateAdminRoute> 
    },
    { 
      path: "/admin/statistique/user/:id/logs", 
      element: <PrivateAdminRoute><AdminStatistiquePageUserLogs /></PrivateAdminRoute> 
    },
    { 
      path: "/admin/statistique/users/logs", 
      element: <PrivateAdminRoute><AdminStatistiquePageUserLogs /></PrivateAdminRoute> 
    },
    { 
      path: "/admin/embargo", 
      element: <PrivateAdminRoute><AdminEmbargoPage /></PrivateAdminRoute> 
    },
    { 
      path: "/admin/embargo/user/:id", 
      element: <PrivateAdminRoute><AdminEmbargoPageUser /></PrivateAdminRoute> 
    },

    //Gestion du challenges
	/*
    { 
      path: "/admin/gestion-challenges", 
      element: <PrivateAdminRoute><AdminFilmAndExtrePage /></PrivateAdminRoute> 
    },
    { 
      path: "/admin/gestion-challenges/:id", 
      element: <PrivateAdminRoute><AdminFilmAndExtrePageFilm /></PrivateAdminRoute> 
    },
    { 
      path: "/admin/gestion-challenges/challenge/:idfilm", 
      element: <PrivateAdminRoute><AdminFilmAndExtrePageFilmEdit /></PrivateAdminRoute> 
    },
    { 
      path: "/admin/gestion-challenges/:idfilm/extre/:idextre", 
      element: <PrivateAdminRoute><AdminFilmAndExtrePageExtre /></PrivateAdminRoute> 
    },
    */
    // Routes protégées par authentification
    { 
      path: "/show/:id", 
      element: <PrivateRoute><Editermonextrait /></PrivateRoute> 
    },
    { 
      path: "/:producteur/:pseudofilm/:pseudosequence/:id", 
      element: <PrivateRoute><Editermonextrait /></PrivateRoute> 
    },
    { 
      path: "/film/:id", 
      element: <Film /> 
    },
    { 
      path: "/messsequencessix", 
      element: <PrivateRoute><MesssequencessixPage /></PrivateRoute> 
    },
    { 
      path: "/mesinfospersonnelles", 
      element: <PrivateRoute><Mesinfospersonnelles /></PrivateRoute> 
    },
    { 
      path: "/mentions-legales", 
      element: <PrivateRoute><MentionslPage /></PrivateRoute> 
    },
    // Routes publiques

    { path: "/conditions-generales-utilisation", element: <ConditionsGeneralesUtilisation /> },
    { path: "/conditions-generales-utilisation-recut", element: <ConditionsGeneralesUtilisationRecut /> },
    { path: "/connexion", element: <Connexion /> },
    { path: "/connexionseaux", element: <Crationcompteviarseaux /> },
    { path: "/register", element: <Register /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/404", element: <NotFound /> },

    // Route dynamique pour les films
    { path: "/:producteur/:pseudofilm", element: <Film /> },
    { path: "/:producteur", element: <CommunautePage /> },
    
    // Route par défaut pour les pages non trouvées
    { path: "*", element: <NotFound /> },
  ]);

  return element;
};

export default ProjectRoutes;
